import React from "react";
import doc3 from "../../assets/img/doctor/sivachandra.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../../CustomTitle";

export default function Sivachandra() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      " Dr. Sivachandra| Fertility Specialist | Kangaroo Care Fertility ",
    Description:
      "Dr. Sivachandra: Pioneering Embryologist Transforming Reproductive Medicine. Expertise in Assisted Reproductive Technologies | kangaroocare Fertility",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>Master of Biotechnology, BSC Biotechnology</p>
            <br />
            <p>
              Department : <span>Embryologist</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Ramanagara</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr . Sivachandra M</h1>
            <p>
              <strong>JUNIOR EMBRYOLOGIST </strong>
            </p>
            <br />
            <h3>Qualification</h3>
            <li>Master of Biotechnology, Periyar University, 2017</li>
            <li>BSc Biotechnology, St. Joseph College, Hosur</li>
            <br />
            <h3>Areas of Expertise:</h3>
            <li>Semen Analysis</li>
            <li>DFI</li>
            <li>IUI Sample Preparation</li>
            <li>Sperm Preparation for ICSI</li>
            <li>Semen Cryopreservation and Thawing</li>
            <li>TESA/PESA/TESE Sample Preparation</li>
            <li>Media Preparation for OPU</li>
            <li>Screening of Oocytes</li>
            <li>Denudation</li>
            <li>Fert Check</li>
            <li>Embryo Grading</li>
          </div>
        </div>
      </div>
    </div>
  );
}
